import { Link } from "react-router-dom";
import useConfig from "../../hooks/config.hook";
import useEpg from "../../hooks/fetch-epg.hook";
import React, { useState } from "react";
import EPG from "../../components/epg/epg.component";
import Tabs from "../../components/tabs/tabs.component";
import useContenidos from "../../hooks/fetch-contenidos.hook";
import Spinner from "../../components/spinner/spinner.component";
import TabItem from "../../components/tab-item/tab-item.component";
import { filtrarProgramasPorFechaFin } from "../../utilities/program-utils";

const GuiaTvPage = () => {
  const [fecha, setFecha] = useState(new Date());
  const [reset, setReset] = useState(false);
  const { canales, isLoading } = useEpg(fecha);
  const [active, setActive] = useState(0);

  const { idListaCanales } = useConfig();
  const { contenidos, isLoadingC } = useContenidos(idListaCanales);

  if (isLoading || isLoadingC) return <Spinner />;

  const ordered = [];
  if (contenidos.length > 0 && canales.length > 0) {
    const contentMap = new Map(canales.map((c) => [c.id_contenido, c]));
    contenidos.forEach((c) => {
      const i = contentMap.get(c.id_contenido);
      if (i) ordered.push(i);
    });
  }

  filtrarProgramasPorFechaFin(ordered, fecha.setMinutes(0));
  const onClickHoy = (tabId) => {
    setActive(tabId);
    setFecha(new Date());
    setReset(true);
  };

  const onClickManiana = (tabId) => {
    setActive(tabId);
    const hoy = new Date();
    const maniana = new Date(
      hoy.getFullYear(),
      hoy.getMonth(),
      hoy.getDate() + 1,
      0,
      0,
      0
    );

    setReset(true);
    setFecha(maniana);
  };

  const tabs = [
    { id: 0, title: "Hoy", active: true, onClick: () => onClickHoy(0) },
    { id: 1, title: "Mañana", active: false, onClick: () => onClickManiana(1) },
  ];

  return (
    <section className="main">
      <div className="main__header">
        <div className="container flex flex--end-y">
          <h1>Guía de TV</h1>
          <Tabs cssClasses="tabs--header tabs--border">
            {tabs.map((tab) => {
              const { id, onClick, title } = tab;
              return (
                <TabItem key={id} active={id === active} onClick={onClick}>
                  <Link to="#" title={title}>
                    {title}
                  </Link>
                </TabItem>
              );
            })}
          </Tabs>
        </div>
      </div>
      <div className="main__content">
        <EPG
          canales={ordered}
          horaInicio={fecha.getHours()}
          reset={reset}
          onReset={() => setReset(!reset)}
        />
      </div>
    </section>
  );
};

export default GuiaTvPage;
