export function createError(code, message, data, original) {
  let error = Object.assign(new Error(message), {
    code,
    data,
    original,
    isCableplayError: true,
  });

  error.toJSON = function () {
    return {
      message: this.message,
      name: this.name,
      data: this.data,
      code: this.code,
      original: this.original,
    };
  };
  return error;
}
