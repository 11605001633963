export const enVivo = (programa) => {
  const inicio = Date.parse(programa.fecha_hora_inicio);
  const fin = Date.parse(programa.fecha_hora_fin);
  const ahora = Date.now();
  return ahora <= fin && ahora >= inicio;
};

export const proximosProgramas = (programa) => {
  const inicio = Date.parse(programa.fecha_hora_inicio);
  const ahora = Date.now();
  return ahora <= inicio;
}

export const duracion = (programa) => {
  const inicio = Date.parse(programa.fecha_hora_inicio);
  const fin = Date.parse(programa.fecha_hora_fin);
  const total = fin - inicio;
  return total / 1000 / 60 / 60;
};

export const progreso = (programa) => {
  const inicio = Date.parse(programa.fecha_hora_inicio);
  const fin = Date.parse(programa.fecha_hora_fin);
  const total = fin - inicio;
  const progreso = total - (fin - Date.now());
  const porcProgreso = Math.floor((progreso * 100) / total);
  return porcProgreso.toString();
};

export const getHoraIniHoraFin = (programa) => {
  const [horaIni, minutosIni] = new Date(programa.fecha_hora_inicio)
    .toLocaleTimeString()
    .slice(0, 5)
    .split(":");
  const [horaFin, minutosFin] = new Date(programa.fecha_hora_fin)
    .toLocaleTimeString()
    .slice(0, 5)
    .split(":");
  return `${horaIni}:${minutosIni} - ${horaFin}:${minutosFin}hs`;
};

export const filtrarProgramasPorFecha = (dateTime, epg) => {
  //console.log(dateTime);
  const filterDate = new Date(dateTime + " 00:00:00").getTime();
  return epg.filter((programa) => {
    const inicio = new Date(
      programa.fecha_hora_inicio.slice(0, 10) + " 00:00:00"
    ).getTime();
    const fin = new Date(
      programa.fecha_hora_fin.slice(0, 10) + " 00:00:00"
    ).getTime();
    let resultado = false;
    if (filterDate === inicio) {
      resultado = true;
    } else {
      if (inicio < filterDate && fin >= filterDate) {
        resultado = true;
      }
    }
    return resultado;
  });
};

export const filtrarProgramasPorFechaFin = (canales, fechaFin) => {
  canales.map((canal) => {
    const epg = canal.epg.filter((programa) => {
      const fechaFinPrograma = new Date(programa.fecha_hora_fin);
      return fechaFinPrograma > fechaFin;
    });

    canal.epg = epg;
    return canal;
  });
};

export const progresoEnHoras = (programa) => {
  const inicio = Date.parse(programa.fecha_hora_inicio);
  const fin = Date.parse(programa.fecha_hora_fin);
  const total = fin - inicio;
  const horaBase = new Date (new Date().setMinutes(0)).setSeconds(0);
  const progreso = total - (fin - horaBase);
  return progreso / 1000 / 60 / 60;
};

export const termino = (programa) => {
  const ahora = Date.now();
  const fin = Date.parse(programa.fecha_hora_fin);
  return fin < ahora;
};

export const displayHoraIniHoraFin = (programa) =>
  `${programa.fecha_hora_inicio.slice(11, 16)} -
  ${programa.fecha_hora_fin.slice(11, 16)}`;
