import useAuth from "./auth.hook";
import { useEffect, useState } from "react";
import useErrorHandler from "./errorhandler.hook";
import { getContenidos } from "../utilities/cds-client";

const useContenidos = (idListaCanales) => {
  const [isLoading, setIsLoading] = useState(true);
  const [contenidos, setContenidos] = useState([]);
  const { auth } = useAuth();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (auth.cds && idListaCanales) {
      getContenidos(auth, idListaCanales)
        .then((response) => {
          const contenidos = response.data.contenidos || [];
          setContenidos(contenidos);
          setIsLoading(false);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [auth, idListaCanales, handleError]);

  return { contenidos, isLoading };
};

export default useContenidos;
