import { eventConfig as conf } from "./config";
import apiSeguimiento from "../../utilities/api-seguimiento";

class PlayerEventHandler {
  constructor(auth, content, player) {
    this.completeSent = false;
    this.player = player;
    this.auth = auth;
    this.content = content;
    this.currentId = 0;
    this.bindEvents = this.bindEvents.bind(this);
    this.onEvent = this.onEvent.bind(this);
    this.sendEvent = this.sendEvent.bind(this);
    this.sendAndScheduleEvent = this.sendAndScheduleEvent.bind(this);
  }

  bindEvents(events) {
    events.forEach((event) => {
      this.player.on(event, this.onEvent);
    });
  }

  onEvent(event) {
    console.log(JSON.stringify(event, null, 2));
    switch (event.type) {
      case "destroy":
      case "error":
      case "playbackfinished":
        if (this.completeSent) return;
        clearInterval(this.currentId);
        this.sendEvent(event.type, 0);
        this.completeSent = true;
        break;
      case "playing":
        this.completeSent = false;
        this.sendAndScheduleEvent(event.type);
        break;
      case "paused":
        if (this.completeSent) return;
        this.sendAndScheduleEvent(event.type);
        break;
      default:
        console.log("Evento no manejado", event.type);
        break;
    }
  }

  sendAndScheduleEvent(event) {
    if (this.currentId !== 0) {
      clearInterval(this.currentId);
    }
    this.sendEvent(event, this.player.getCurrentTime());

    const sendEvent = (pos) => this.sendEvent(event, pos);
    this.currentId = setInterval(() => {
      sendEvent(this.player.getCurrentTime());
    }, conf[event].timeout);
  }

  sendEvent(event, currentPosition) {
    console.log("Sending event ", conf[event].seguimiento, currentPosition);
    try {
      apiSeguimiento.seguimiento(
        this.content,
        conf[event].seguimiento,
        this.auth,
        this.content.playback,
        currentPosition
      );
    } catch (error) {
      console.log("error: " + error);
    }
  }
}

export default PlayerEventHandler;
