import React from "react";
import EpgPrograma from "../epg-programa/epg-programa.component";

import "./epg-programas.styles.scss";

const EpgProgramas = ({ canales, animate, hourWidth }) => {
  return (
    <div className="guide__programs js-programs" style={animate}>
      {canales.map((canal) => (
        <div key={canal.id} className="programs">
          {canal.epg.map((programa, i) => (
            <EpgPrograma key={programa.id} programa={programa} hourWidth={hourWidth} first={i===0} idCanal={canal.public_id}/>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EpgProgramas;
