import React from "react";
import { Link } from "react-router-dom";
import "./item-canal.styles.scss";
const ItemCanal = ({ canal }) => {
  return (
    <div className="live">
      <Link to={"canal/" + canal.public_id} title="Ver Canal">
        <div className="live__figure">
          <img src={canal.imagen_principal} alt={canal.nombre} />
        </div>
        <div className="live__figurecaption">
          <p>{canal.nombre}</p>
        </div>
      </Link>
    </div>
  );
};

export default ItemCanal;
