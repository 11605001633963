import React from "react";

const TabItem = ({ children, active, onClick }) => {
  return (
    <div
      className={`tabs__item ${active ? "tabs__item--active" : ""}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

export default TabItem;
