import React from "react";
import "./progress-bar.styles.scss"
const ProgressBar = ({ progress }) => {
  return (
    <div className="progress">
      <div className="progress__bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
