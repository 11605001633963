const handleWithMessageFromConfig =
  (getMessage) =>
  ({ messages, config }) =>
  (err) => {
    messages.add({
      type: "modal",
      text: getMessage(config) || err.message,
    });
  };

export default handleWithMessageFromConfig;