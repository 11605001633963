import React from "react";
import "./tabs.styles.scss"

const Tabs = ({ children, size = "small", cssClasses = "" }) => {
  return (
    <div className={`tabs ${cssClasses}`} >
      <div className={`tabs__items container--${size}`}>{children}</div>
    </div>
  );
};

export default Tabs;
