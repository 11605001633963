import "./user.styles.scss";
import React from "react";
import useUser from "../../hooks/user.hook";

const User = () => {
  const { user, login, logout } = useUser();
  return (
    <div className="user">
      <div className="user-profile">
        {user ? (<>
          <div className="user-profile__name">
            <div >
              <p>{user.profile.username}</p>
            </div>
          </div>
            <div className="link"  onClick={() => logout()}>
              Salir
            </div>
            </>
        ) : (
          <div className="link" onClick={() => login()}>
            Ingresar
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
