import React from "react";
import { useState } from "react";

/* Contexto para  manejar mensajes que se depliegan al usuario y que pueden
    ser generados por distintos componentes de la app */

export const MessagesContext = React.createContext();

// El estado puede almacenar ditintos tipos de mensaje,
const initialMessagesState = {
  modal: [],
  notificacion: [],
  error: [],
};

export const MessagesContextProvider = ({ children }) => {
  const [messages, setMessages] = useState(initialMessagesState);

  const addMessage = (params) => {
    const { type = "modal" } = params;
    return {
      ...messages,
      [type]: [params].concat(messages[type]),
    };
  };
  const popMessage = type => ({
      ...messages,
      [type]: messages[type].slice(1),
  })

  const messagesApi = {
    messages,
    add: params => setMessages(addMessage(params)),
    pop: type => setMessages(popMessage(type)),
  };

  return (
    <MessagesContext.Provider value={messagesApi}>
      {children}
    </MessagesContext.Provider>
  );
};
