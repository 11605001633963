import { useEffect, useState } from "react";
import useAuth from "./auth.hook";
import { getBanners } from "../utilities/cds-client";
import useErrorHandler from "./errorhandler.hook";

const useBanners = (idLista) => {
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const { auth } = useAuth();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (auth.cds && idLista) {
      getBanners(auth, idLista)
        .then((response) => {
          const banners = (typeof response?.data?.data[0] === 'undefined')?[]:response.data.data[0]?.banners || [];
          setBanners(banners);
          setIsLoading(false);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [auth, idLista, handleError]);

  return { banners, isLoading };
};

export default useBanners;
