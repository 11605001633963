import React from "react";
import "./carousel.styles.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const Carousel = ({
  header,
  className,
  items = 6,
  autoWidth = true,
  children,
  margin = 5,
  sectionClass="carousel"
}) => {
  return (
    <section className={sectionClass}>
      <div className="carousel__container">
        <div className="carousel__header container">{header}</div>
        <OwlCarousel
          className={`carousel__content owl-carousel owl-theme ${className} container`}
          margin={margin}
          autoWidth={autoWidth}
          nav
          items={items}
        >
          {React.Children.map(children, (child) => {
            return <div className="carousel__item">{child}</div>;
          })}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Carousel;
