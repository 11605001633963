const playerConfig = {
  key: "a488d68f-aedf-4be7-b687-23403ecb4520",
  playback: { autoplay: true, muted: false },
  style: { width: "75%", aspectratio: "16:9" },
  location: {
    ui: "//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
    ui_css: "//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
  },
  tweaks: {
    stop_download_on_pause: true,
  },
};

export const eventConfig = {
  playing: {
    timeout: 30000,
    seguimiento: "play",
  },
  paused: {
    timeout: 90000,
    seguimiento: "pause",
  },
  destroy: {
    seguimiento: "complete",
  },
  error: {
    seguimiento: "complete",
  },
  playbackfinished: {
    seguimiento: "complete",
  },
};

export default playerConfig;
