import { useHistory } from "react-router-dom";
import useUser from "../../hooks/user.hook";
import React, { useContext, useRef } from "react";
import { MessagesContext } from "../../contexts/messages-context";
import Panel from "../panel/panel.component";
import ProgressBar from "../progress-bar/progress-bar.component";
import {
  enVivo,
  proximosProgramas,
  progreso,
  displayHoraIniHoraFin,
} from "../../utilities/program-utils";
import "./guia-canal.styles.scss";


const GuiaCanal = ({ epg }) => {
  const history = useHistory();
  const messages = useContext(MessagesContext);
  const messagesRef = useRef(messages);
  const { user, login } = useUser();

  const goPlay = (id) => {
    if (typeof user === 'undefined') {
      const messages = messagesRef.current;
            messages.add({
              type:'modal',
              text: "Por favor loguearse para ver contenidos",
              onBtnClick: () => login(),
      });
    } else {
      history.push(`/play/${id}`);
    }
  }

  return (
    <div className="panel-guide container--small">
      {epg.map((programa) => {
        const horaPrograma = displayHoraIniHoraFin(programa);
        const {id, id_canal, nombre_programa} = programa;

        if(proximosProgramas(programa) || enVivo(programa)){
          return enVivo(programa)? (
            <div className="go_play" onClick={() => goPlay(id_canal)}>
              <Panel key={id} size="small" type="now" header={<span>{horaPrograma}</span>}>
                <div className="flex flex--between-x">
                  <p>{nombre_programa}</p>
                  <span></span>
                </div>
                <ProgressBar progress={progreso(programa)}></ProgressBar>
              </Panel>
            </div>
          ) : (
            <div className="go_play" onClick={() => goPlay(id_canal)}>
              <Panel key={id} size="small" header={<span>{horaPrograma}</span>}>
                <div className="flex flex--between-x">
                  <p>{nombre_programa}</p>
                  <span></span>
                </div>
              </Panel>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default GuiaCanal;
