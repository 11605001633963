import axios from "axios";
import * as codes from "../errors/codes";
import { createError } from "../errors/createError";

const buildRequestConfig = (auth) => ({
  headers: {
    "x-service-id": auth.sesion.id_servicio,
    "x-frontend-id": auth.sesion.id_frontend,
    "x-system-id": process.env.REACT_APP_SYSTEM_ID,
    authorization: auth.cds.autorizacion.token,
  },
});

const getCodeFromError = (err) => {
  const cdsCode = err.response && err.response.data.code_interno;
  return cdsCode || codes.UNKNOWN_ERROR;
};
const getDataFromError = (err) => (err.response ? err.response.data : {});

const handleCdsError = (err) => {
  const code = getCodeFromError(err);
  throw createError(code, err.message, getDataFromError(err), err);
};

const callCds = async (url, auth) => {
  try {
    return await axios.get(url, buildRequestConfig(auth));
  } catch (error) {
    handleCdsError(error);
  }
};

const getBanners = async (auth, idLista) =>
  await callCds(
    `${process.env.REACT_APP_CDS_API_CONTENIDOS}listadebanners?id=${idLista}&token=${auth.sesion.token}`,
    auth
  );

const getCanal = async (public_id, auth) =>
  await callCds(
    `${process.env.REACT_APP_CDS_API_CONTENIDOS}canales/${public_id}?token=${auth.sesion.token}`,
    auth
  );

const getContenidos = async (auth, idListaCanales) =>
  await callCds(
    `${process.env.REACT_APP_CDS_API_CONTENIDOS}listas/${idListaCanales}?token=${auth.sesion.token}`,
    auth
  );

const getEPG = async (fecha, auth) =>
  await callCds(
    `${process.env.REACT_APP_CDS_API_CONTENIDOS}canales/epg?token=${
      auth.sesion.token
    }&dias_previos=0&dias_siguientes=1&fecha=${fecha
      .toISOString()
      .slice(0, 10)}`,
    auth
  );

const getEPGCanal = async (public_id, fecha, auth) =>
  await callCds(
    `${process.env.REACT_APP_CDS_API_CONTENIDOS}canales/epg/${public_id}?token=${auth.sesion.token}&limit=500&dias_siguientes=6&fecha=${fecha}`,
    auth
  );

export { getBanners, getCanal, getContenidos, getEPG, getEPGCanal };
