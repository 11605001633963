import React from "react";
import { Link } from "react-router-dom";
import Carousel from "../carousel/carousel.component";
import ItemCanal from "../item-canal/item-canal.component";
import useContenidos from "../../hooks/fetch-contenidos.hook";
import Spinner from "../../components/spinner/spinner.component";
import useConfig from "../../hooks/config.hook";

const Canales = () => {
  const { idListaCanales } = useConfig();

  const { contenidos, isLoading } = useContenidos(idListaCanales);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Carousel
          header={
            <React.Fragment>
              <h2>Canales</h2>
              <Link to="/guia" className="link">
                Ver todos <span className="i i--next i--green"></span>
              </Link>
            </React.Fragment>
          }
          items={6}
          margin={5}
        >
          {contenidos.map((canal) => (
            <ItemCanal key={canal.id} canal={canal} />
          ))}
        </Carousel>
      )}
    </>
  );
};

export default Canales;
