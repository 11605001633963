import { useState, useEffect } from "react";
import useAuth from "./auth.hook";
import { getEPGCanal } from "../utilities/cds-client";
import useErrorHandler from "./errorhandler.hook";

const useCanalEpg = (public_id, fecha) => {
  const [isLoading, setIsLoading] = useState(true);
  const [canalEpg, setcanalEpg] = useState([]);
  const { auth } = useAuth();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (auth.cds) {
      getEPGCanal(public_id, fecha, auth)
        .then((response) => {
          const canal = response.data.data || [];
          setcanalEpg(canal);
          setIsLoading(false);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [auth, public_id, fecha, handleError]);

  return { canalEpg, isLoading };
};

export default useCanalEpg;
