import "./epg-programa.styles.scss";
import { useHistory } from "react-router-dom";
import useUser from "../../../hooks/user.hook";
import React, { useContext, useRef } from "react";
import { MessagesContext } from "../../../contexts/messages-context";

import {
  enVivo,
  duracion,
  progresoEnHoras,
  termino,
  displayHoraIniHoraFin,
} from "../../../utilities/program-utils";

const EpgPrograma = ({ programa, first, hourWidth, idCanal }) => {
  const history = useHistory();
  const messages = useContext(MessagesContext);
  const messagesRef = useRef(messages);
  const { user, login } = useUser();

  const vivo = enVivo(programa);
  const fin = termino(programa);
  const duracionPrograma = duracion(programa);
  let width = duracionPrograma * hourWidth;
  if (vivo && first) {
    width = hourWidth * (duracion(programa) - progresoEnHoras(programa));
  } else if (fin) {
    const minutesFin = new Date(programa.fecha_hora_fin).getMinutes();
    width = (minutesFin / 60) * hourWidth;
  }
  const goPlay = (id) => {
    if (typeof user === 'undefined') {
      const messages = messagesRef.current;
            messages.add({
              type:'modal',
              text: "Por favor loguearse para ver contenidos",
              onBtnClick: () => login(),
      });
    } else {
      history.push(`/play/${id}`);
    }
  }

  return (
    <div
      className={`programs__item ${
        vivo ? "programs__item--now" : fin ? "programs__item--fin" : ""
      }`}
      style={{ width: `${width}px` }}
      onClick={() => goPlay(idCanal)}
    >
      <div></div>
      <span>{displayHoraIniHoraFin(programa)}</span>
      <p>{programa.nombre_programa}</p>
    </div>
  );
};

export default EpgPrograma;
