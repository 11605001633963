import * as codes from "./codes";
import { createError } from "./createError";
import { getHandler, getMessage } from "./errorMapping";

const wrapError = (err) => {
  if (err.isCableplayError) {
    return err;
  } else {
    return createError(codes.UNKNOWN_ERROR, {}, err);
  }
};

/**
 * Recibe los contextos y ejecuta el handler correspondiente para el error.
 */
const handleError = (contexts) => (originalError) => {
  console.log("Manejando error", originalError);
  const err = wrapError(originalError);
  const code = err.code;
  err.message =
    getMessage(code) || err.message || `Se produjo un error. (código: ${code})`;
  const handler = getHandler(code);
  const handleWithContext = handler(contexts);
  handleWithContext(err);
};

export default handleError;
