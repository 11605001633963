import "./player.styles.scss";
import { useEffect } from "react";
import playerConfig from "./config";
import React, { useRef } from "react";
import bitmovin from "bitmovin-player";
import useAuth from "../../hooks/auth.hook";
import PlayerEventHandler from "./player-event-handler";

const Player = ({ content }) => {
  const player = useRef(null);
  const eventHandler = useRef(null);
  const { auth } = useAuth();

  const setupPlayer = () => {
    const bitmovinPlayer = new bitmovin.Player(
      document.getElementById("player"),
      playerConfig
    );
    bitmovinPlayer
      .load({ hls: content.playback.url })
      .then(() => {
        player.current = bitmovinPlayer;
      })
      .catch((err) => console.error(err));

    eventHandler.current = new PlayerEventHandler(
      auth,
      content,
      bitmovinPlayer
    );

    eventHandler.current.bindEvents([
      bitmovin.PlayerEvent.Playing,
      bitmovin.PlayerEvent.Paused,
      bitmovin.PlayerEvent.Destroy,
      bitmovin.PlayerEvent.Error,
      bitmovin.PlayerEvent.PlaybackFinished,
    ]);
  };

  const destroyPlayer = () => {
    if (player.current !== null) {
      player.current
        .destroy()
        .then()
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    setupPlayer();
    return destroyPlayer;
  }, []);

  return <div id="player"></div>;
};

export default Player;
