import React from "react";
import useCanal from "../../hooks/fetch-canal.hook";
import Spinner from "../../components/spinner/spinner.component";
import SetUpPlayer from "../../components/player/set-up.component";

const PlayPage = ({ match }) => {
  const public_id = match.params.id;
  const { canal, isLoadingC } = useCanal(public_id);
  return isLoadingC ? <Spinner /> : <SetUpPlayer contenido={canal} />;
};

export default PlayPage;
