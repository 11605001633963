import React from "react";
import "./epg-nav.styles.scss"

const EpgNav = ({ prev, next, nextDisabled, prevDisabled }) => {
  return (
    <div className="guide-nav">
      <div
        className={`js-guide-next guide-nav__btn guide-nav__btn--next ${
          nextDisabled ? "guide-nav__btn--disabled" : ""
        }`}
        onClick={() => next()}
      >
        <div className="i i--next">Next</div>
      </div>
      <div
        className={`js-guide-prev guide-nav__btn guide-nav__btn--prev ${
          prevDisabled ? "guide-nav__btn--disabled" : ""
        }`}
        onClick={() => prev()}
      >
        <div className="i i--prev">Prev</div>
      </div>
    </div>
  );
};

export default EpgNav;
