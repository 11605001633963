import { DateTime } from "luxon";

//Dates

export const getIsoDate = (date) => {
  //console.log(DateTime.local());
  var now = (date)?date:DateTime.local();
  return now.toISODate();
}

export const getNextHours = (count = 0, horaInicio) => {
  const hours = [];
  DateTime.fromObject({ hour: horaInicio });
  for (let i = 0; i < count; i++) {
    let now = DateTime.fromObject({ hour: horaInicio });
    now.plus({ hours: i });
    hours.push(`${now.plus({ hours: i }).toFormat("HH")}:00hs`);
  }
  return hours;
};

export const getNextDates = (count) => {
  let dates = [];
  for (let i = 0; i < count; i++) {
    let date = new Date();
    dates[i] = new Date(date.setDate(date.getDate() + (i + 1)));
  }
  return dates;
};

export const getFormatedNextDates = (count) => formatDates(getNextDates(count));

export const getTodayAndNextDatesMap = (count) => {
  const nextDays = getNextDates(count).map((date) => ({
    formated: `${capitalize(
      date.toLocaleString("es-UY", { weekday: "long" })
    )} ${date.getDate()}`,
    date,
  }));

  return [
    {
      formated: "Hoy",
      date: new Date(),
    },
    ...nextDays,
  ];
};

export function formatDate(dateTime) {
  var date = dateTime.toLocaleDateString().split(",");
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, day, month].join('-');
}

export const formatDates = (dates) => {
  return dates.map((date) => {
    return dayOfWeek(date);
  });
};

const dayOfWeek = (date) =>
  capitalize(date.toLocaleString("es-UY", { weekday: "long" }));

//Strings

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const sisOpUsuario = () =>{
  let name = "Unknown OS"; 
  if (navigator.userAgent.indexOf("Win") !== -1) name =  
    "Windows OS"; 
  if (navigator.userAgent.indexOf("Mac") !== -1) name =  
    "Macintosh"; 
  if (navigator.userAgent.indexOf("Linux") !== -1) name =  
    "Linux OS"; 
  if (navigator.userAgent.indexOf("Android") !== -1) name =  
    "Android OS"; 
  if (navigator.userAgent.indexOf("like Mac") !== -1) name =  
    "iOS";

    return name;
}
