import * as codes from "../errors/codes";
import { createError } from "../errors/createError";
import { addSlash } from "./addSlash";

const BeClient = {
  getConfig: async () => {
    const url = addSlash(process.env.REACT_APP_API_BACKEND);
    const result = await fetch(`${url}config`, {
      defaultRequestConfig,
      method: "GET",
    });
    await handleErrors(result);
    return await result.json();
  },

  autorizarAnonimo: async () => {
    const url = addSlash(process.env.REACT_APP_API_BACKEND);
    const result = await fetch(`${url}autorizar-anonimo`, defaultRequestConfig);
    await handleErrors(result);
    return await result.json();
  },

  autorizar: async (user) => {
    const url = addSlash(process.env.REACT_APP_API_BACKEND);
    const result = await fetch(`${url}autorizar/`, {
      ...defaultRequestConfig,
      body: JSON.stringify({
        usuario: user.profile.username,
        dominio: "lua",
        autenticacion_jwt: user.id_token,
      }),
    });
    await handleErrors(result);
    return await result.json();
  },
  cerrarSesion: async (auth) => {
    const url = addSlash(process.env.REACT_APP_API_BACKEND);
    const result = await fetch(`${url}sesion/cerrar/`, {
      ...defaultRequestConfig,
      body: JSON.stringify({
        token: auth.sesion.token,
      }),
    });
    await handleErrors(result);
    return await result.json();
  },

  setup: async (public_id, auth) => {
    // Esto lo puse para probar la logica de renovacion de login.
    //throw createError("9902", "error a drede", {});
    const url = addSlash(process.env.REACT_APP_API_BACKEND);
    const result = await fetch(
      `${url}setup?token=${auth.sesion.token}&public_id=${public_id}&id_frontend=${auth.sesion.id_frontend}`,
      {
        ...defaultRequestConfig,
        method: "GET",
      }
    );
    await handleErrors(result);
    return await result.json();
  },
};

export const errorCodes = {
  cableplay: {
    USUARIO_NO_HABILITADO: "404001",
  },
  cds: {
    LUA_JWT_EXPIRADO: "9902",

    SESION_NO_ENCONTRADA: "9407",
    SESION_USUARIO_REQUERIDA: "9408",
    SESION_JWT_EXPIRADO: "9912",
    SESION_IP_INVALIDA: "9413",
    SESION_JWT_ERROR: "9911",

    SETUP_ERROR_CDS_AMP: "9505",
    SETUP_NO_UY: "9601-NOUY",
    SETUP_SOLO_UY: "9601-SOLOUY",
    SETUP_AUTHREQ: "9601-AUTHREQ",
    SETUP_REPRODUCCIONES_SIMULTANEAS: "9601-REP_SIM",
    SETUP_REPRODUCCIONES_SIMULTANEAS_TVOD: "9601-TVOD_SIM_1",
    SETUP_SESIONES_SIMULTANEAS_NO_PERMITIDAS: "9406",
    SETUP_USUARIO_REQUERIDO_CDS_ALTERNATIVO: "9503",
    SETUP_CDS_DISPOSITIVOS_SIMULTANEOS_NO_PERMITIDOS: "9410",
    SETUP_CDS_DISPOSITIVO_DESHABILITADO: "9411",
    SETUP_REPRODUCCION_CONTENIDO_DESHABILITADO: "9501",
    SETUP_REPRODUCCION_CONTENIDO_ELIMINADO: "9502",
    SETUP_REPRODUCCION_CONTENIDO_NO_EN_PUBLICACION: "9504",
    SETUP_IP_NO_VALIDA_REPRODUCIR: "9506",
    SETUP_PAGO_CDS_EVERGENT_CODE: "101",
    SETUP_AUTHREQ_CODE: "102",
    SETUP_CONTENIDO_NO_ENCONTRADO: "1001",
  },
};

// Fetch no hace reject de la promise si el error no es un error de conexion o similar.
// Lanzo el body para manejar el codigo de error mas arriba.
const handleErrors = async (response) => {
  if (!response.ok) {
    const body = await response.json();
    console.log("be-client-error", body);
    const code = body.code || codes.UNKNOWN_ERROR;
    throw createError(code, body.message, body);
  }
};

const defaultRequestConfig = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export default BeClient;
