import React, { useState, useEffect } from "react";
import { UserManager, WebStorageStateStore } from "oidc-client";

// Si viene este path es para el signinsilent y no debo renderear la app.
const signinSilentCallbackPath = "/signin-silent-callback";

export const OIDCUserContext = React.createContext(null);

export const getUserManager = () =>
  new UserManager({
    authority: process.env.REACT_APP_LUA_OPENID_URL,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    silent_redirect_uri:
      process.env.REACT_APP_REDIRECT_URI + signinSilentCallbackPath,
    silentRequestTimeout: process.env.REACT_APP_SILENT_REQUEST_TIMEOUT,
    response_type: "id_token token",
    scope: "openid",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_REDIRECT_URI,
    extraQueryParams: {
      service: process.env.REACT_APP_CAS_SERVICE_URL,
    },
    loadUserInfo: false,
  });

export const isRedirectingFromOIDC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(
    window.location.hash.replace("#", "?")
  );
  return Boolean(
    searchParams.get("code") ||
      searchParams.get("id_token") ||
      searchParams.get("session_state") ||
      hashParams.get("code") ||
      hashParams.get("id_token") ||
      hashParams.get("session_state")
  );
};

const isSigninSilentCallback = () =>
  window.location.pathname === signinSilentCallbackPath;

export const OIDCUserProvider = ({ children }) => {
  const provider = isSigninSilentCallback()
    ? OIDCUserProviderSilent
    : OIDCUserProviderContent;
  return provider({ children });
};

export const OIDCUserProviderSilent = ({ children }) => {
  const userManager = getUserManager();
  userManager.signinSilentCallback();
  return <div> SilentCallback </div>;
};

export const OIDCUserProviderContent = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const userManager = getUserManager();

  useEffect(() => {
    const getUser = async () => {
      if (isRedirectingFromOIDC()) {
        const user = await userManager
          .signinRedirectCallback()
          .catch((e) => console.log(e));
        if (user) {
          setUser(user);
          setIsLoadingUser(false);
          window.location.hash = "";
          return;
        }
      }

      const user = await userManager.getUser();
      if (user && !user.expired) {
        setUser(user);
      }
      setIsLoadingUser(false);
    };
    getUser();
    // eslint-disable-next-line
  }, []);

  const login = () => {
    userManager.signinRedirect({
      redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}${window.location.pathname}`,
    });
  };

  const logout = () => {
    userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    userManager.clearStaleState();
    setUser(undefined);
  };

  const removeUser = () => {
    userManager.removeUser();
    setUser(undefined);
  };

  const silentLogin = () =>
    userManager.signinSilent().then((user) => {
      console.log("Silent login: ", user);
      if (user) {
        setUser(user);
        setIsLoadingUser(false);
      }
      return user;
    });

  return (
    <OIDCUserContext.Provider
      value={{ login, silentLogin, logout, removeUser, user, isLoadingUser }}
    >
      {children}
    </OIDCUserContext.Provider>
  );
};
