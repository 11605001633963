import React from "react";
import { Switch, Route } from "react-router-dom";
import PlayPage from "./pages/play/play.component";
import HomePage from "./pages/home/home.component";
import { AuthProvider } from "./contexts/auth-context";
import CanalPage from "./pages/canal/canal.components";
import { ConfigProvider } from "./contexts/config-context";
import GuiaTvPage from "./pages/guia-tv/guia-tv.component";
import { OIDCUserProvider } from "./contexts/oidc-user-context";
import Header from "./components/layout/header/header.component";
import Footer from "./components/layout/footer/footer.component";
import { Messages } from "./components/messages/messages.component";
import { MessagesContextProvider } from "./contexts/messages-context";

function App() {
  return (
    <div className="App">
      <ConfigProvider>
        <MessagesContextProvider>
          <OIDCUserProvider>
            <AuthProvider>
              <Header />
              <Messages />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/canal/:public_id" component={CanalPage} />
                <Route exact path="/guia" component={GuiaTvPage} />
                <Route exact path="/play/:id" component={PlayPage} />
              </Switch>
              <Footer />
            </AuthProvider>
          </OIDCUserProvider>
        </MessagesContextProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
