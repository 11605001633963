import React from "react";
import Player from "./player.component";
import Spinner from "../spinner/spinner.component";
import useSetupReproduccion from "../../hooks/fetch-setup.hook";
import { useEffect } from "react";
import { useHistory } from "react-router";

const SetUpPlayer = ({ contenido }) => {
  const { urlReproduccion, isLoading, error } = useSetupReproduccion(
    contenido.public_id
  );

  const history = useHistory();

  useEffect(() => {
    if (error) {
      history.push(`/canal/${contenido.public_id}`);
    }
  });

  return isLoading ? (
    <Spinner />
  ) : (
    <Player content={{ ...contenido, playback: urlReproduccion }} />
  );
};

export default SetUpPlayer;
