import React from "react";
import "./button.styles.scss"

const Button = ({children, size, icon , onClick }) => {
  return (
    <div className="flex" onClick={onClick}>
      <button  className={`btn ${size ? `btn--${size}` : ""}`}>
        <i className={`i i--${icon}`}></i>{children}
      </button>
    </div>
  );
};

export default Button;
