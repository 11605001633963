import { useState, useEffect } from "react";
import useAuth from "./auth.hook";
import { getEPG } from "../utilities/cds-client";
import useErrorHandler from "./errorhandler.hook";

const useEpg = (fecha) => {
  const [isLoading, setIsLoading] = useState(true);
  const [canales, setCanales] = useState([]);
  const { auth } = useAuth();
  const handleError = useErrorHandler();


  useEffect(() => {
    if (auth.cds) {
      getEPG(fecha, auth)
        .then((response) => {
          const canales = response.data.data || [];
          setCanales(canales);
          setIsLoading(false);
        })
        .catch(err => {
          handleError(err);
        });
    }
  }, [auth, fecha, handleError]);

  return { canales, isLoading };
};

export default useEpg;
