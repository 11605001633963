import { useCallback } from "react";
import AuthContext from "../contexts/auth-context";
import { MessagesContext } from "../contexts/messages-context";
import { OIDCUserContext } from "../contexts/oidc-user-context";
import handleError from "../errors/handler";
import { useHistory } from "react-router-dom";
import { ConfigContext } from "../contexts/config-context";
import { useContext, useRef, useEffect } from "react";

/**
 * Retorna una funcion que maneja los errores.
 * Para un componente siempre retorna la misma instancia de la funcion.
 */
const useErrorHandler = () => {
  // Obtengo los valores del contexto actualizados
  const config = useContext(ConfigContext);
  const auth = useContext(AuthContext);
  const messages = useContext(MessagesContext);
  const user = useContext(OIDCUserContext);
  const history = useHistory();
  // Creo una referencia para almacenar los valores del contexto
  const ctxRef = useRef({ config, auth, messages, user, history });

  // Actualizo la referencia.
  useEffect(() => {
    ctxRef.current = { config, auth, messages, user, history };
  }, [ctxRef, config, auth, messages, user, history]);

  // Genero un callback (err) => () que usa la referencia.
  // Como la instancia de la referncia es siempre la misma,
  // useCallback retorna siempre la misma funcion. pero esta
  // accesde al contexto actualizado.
  return useCallback(getErrorHandler(ctxRef), [ctxRef]);
};

const getErrorHandler = (ctxRef) => (error) => {
  const currentContexts = ctxRef.current;
  handleError(currentContexts)(error);
};

export default useErrorHandler;
