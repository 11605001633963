import React from "react";
import "./panel.styles.scss";

const Panel = ({ header, size, type, children }) => {
  return (
    <div
      className={`panel ${type ? `panel--${type}` : ""} ${
        size ? `panel--${size}` : ""
      }`}
    >
      {header ? <div className="panel__header">{header}</div> : ""}
      <div className="panel__content">{children}</div>
    </div>
  );
};

export default Panel;
