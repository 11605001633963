import { DateTime } from "luxon";
import { sisOpUsuario } from "../utilities/utility-functions";

const apiSeguimiento = {
  seguimiento: (contenido, accion, auth, playback, current_position) => {
    var now = DateTime.local();
    now = DateTime.fromISO(now).toFormat("yyyy-LL-dd HH:mm:ss");
    
    if(contenido?.public_id){
      const fetchData = async (url) => {
        var formData = new FormData();
        formData.append(
          "api_key",
          `${process.env.REACT_APP_API_SEGUIMIENTO_KEY}`
        );
        formData.append("report_date_time", now);
        formData.append("frontend", auth.sesion.id_frontend);
        formData.append("sesion_token", auth.sesion.token);
        formData.append("sesion_type", "usuario");
        formData.append("customer_id", auth.sesion.id_usuario);
        formData.append("customer_reference_id", auth.sesion.id_referencia);
        formData.append("customer_user", auth.cds.autorizacion.user);
        formData.append("customer_domain", auth.sesion.dominio);
        formData.append("device_id", auth.sesion.id_dispositivo);
        formData.append("so", sisOpUsuario());
        formData.append("service", auth.sesion.id_servicio);
        formData.append("contenido_nombre", contenido.nombre);
        formData.append("contenido_proveedor", contenido.id_proveedor);
        formData.append("contenido_id", contenido.id);
        formData.append("contenido_tipo", "CANAL");
        formData.append("content_length", 0);
        formData.append("playback_start_position", 0);
        formData.append("playback_current_position", current_position);
        formData.append("playback_url", playback.url);
        formData.append("playback_token", playback.token_reproduccion);
        formData.append("accion", accion);

        const response = await fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .catch((error) => "")
          .then((response) => "");
      };

      if (auth.cds) {
        fetchData(`${process.env.REACT_APP_API_SEGUIMIENTO_URL}reproduccion`);
      }
    }  
  },
};

export default apiSeguimiento;
