import AuthContext from "../contexts/auth-context";
import { useEffect, useState, useContext } from "react";
import BeClient from "../utilities/cableplay-be-client";
import useErrorHandler from "./errorhandler.hook";

const useSetupReproduccion = (public_id) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [urlReproduccion, setUrlReproduccion] = useState(null);
  const { auth } = useContext(AuthContext);
  const handleError = useErrorHandler();


  useEffect(() => {
      if (auth.cds) {
      BeClient.setup(public_id, auth)
        .then(response => {
          const setup = response || null;
          if (typeof setup.url !== "undefined") {
            var simple_setup = {'url':setup.url.suggested.url,'token_reproduccion':setup.token_reproduccion};
            setUrlReproduccion(simple_setup);
            setIsLoading(false);
          } else {
            console.error(setup.message);
            setError(true);
          }
        })
        .catch(err => {
          setError(true);
          handleError(err);
        });
    }
  }, [auth, public_id, handleError]);

  return { urlReproduccion, isLoading, error };
};

export default useSetupReproduccion;
