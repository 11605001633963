import React from "react";
import logo from "../../../assets/images/logo-cable-play-white.png";
import "./footer.styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__container">
          <div className="footer__item">
            <div className="logo">
              <img src={logo} alt="Cable Play" width="70px" />
            </div>
          </div>
          <div className="footer__item flex--end">
            <p>
              Cable Play, el servicio de televisión por internet de los cables
              operadores integrantes de la Cámara Uruguaya de Televisión para
              Abonados (CUTA)
            </p>
            <hr />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
