import React from "react";
import SlideItem from "../../components/slide/slide-item.component";
import Canales from "../../components/canales/canales.component";
import Spinner from "../../components/spinner/spinner.component";
import useBanners from "../../hooks/fetch-banners.hook";
import useConfig from "../../hooks/config.hook";
import "../../components/carousel/carousel.styles.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const HomePage = ({ history }) => {
  const { idListaBannersHome } = useConfig();
  const { banners, isLoading } = useBanners(idListaBannersHome);

  if (isLoading) return <Spinner />;
  return typeof banners[0] !== 'undefined' ? (
    <>
    <section className="slide slide--big">
      <OwlCarousel
        className={`owl-carousel owl-theme owl-slide owl-loaded owl-drag`}
        margin={0}
        autoWidth={false}
        nav
        items={1}
        loop={true}
      >
        {banners.map((banner) => {
            const { id_banner, link, imagenes, titulo, imagen } = banner;
            const imgs = (imagenes!==null)?imagenes:"{}";
            const backgroundImage = JSON.parse(imgs)[process.env.REACT_APP_FRONTEND_ID]?.poster_h?.url ?? imagen ?? "";
            return (
            <SlideItem key={id_banner} backgroundImage={backgroundImage} link={link}>
                <h1 className="banner_home_h1">
                    <small>Este mes en Cable Play</small>
                    <span>{titulo}</span>
                </h1>
            </SlideItem>
            );
        })}
      </ OwlCarousel>
      </section>
      <Canales />
      </>
  ): (
      <Canales />
  );
};

export default HomePage;
