import React from "react";
import "./modal.styles.scss";

const Modal = ({ title, text, onClose, btnText, onBtnClick}) => (
  <div className="modal js-modal">
    <div className="modal__content">
      <h2>{title}</h2>
      <p>{text}</p>
      <button className="btn btn--big" onClick={() => (onBtnClick||onClose)()}>
        {btnText || "Aceptar"}
      </button>
      <div className="modal__close js-close-modal" onClick={() => onClose()}>
        <div className="i i--close"></div>
      </div>
    </div>
  </div>
);

export default Modal;
