import { useEffect } from "react";
import React, { useState } from "react";
import BeClient from "../utilities/cableplay-be-client";

const ConfigContext = React.createContext({});

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    BeClient.getConfig()
      .then((config) => setConfig(config))
      .catch((err) => console.log(err));
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext };
