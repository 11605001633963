const handleSesionUsuarioExpirada = ({ user, messages }) => (err) => {
  const handleSilentLoginFailed = () => {
    user.removeUser();
    messages.add({
      type: "modal",
      text: "Su sesión ha expirado. Debe ingresar nuevamente.",
      onBtnClick: () => user.login(),
    });
  };

  user
    .silentLogin()
    .then((user) => {
      if (!user) {
        handleSilentLoginFailed();
      }
    })
    .catch((err) => handleSilentLoginFailed());
};

export default handleSesionUsuarioExpirada;
