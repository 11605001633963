import React from "react";
import "./epg-canales.styles.scss";
import { useHistory } from "react-router-dom";


const EpgCanales = ({ canales }) => {
  const history = useHistory();

  const goCanal = (id) => {
      history.push(`/canal/${id}`);
  };
  return (
    <div className="guide__channel">
      {canales.map((canal) => {
        const { id, nombre, imagen_principal, public_id } = canal;
        return (
          <div key={id} className="channel">
            <img
              src={imagen_principal}
              alt={nombre}
              onClick={() => goCanal(public_id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EpgCanales;
