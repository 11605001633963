import { useState } from "react";

const useStickyState = (defaultValue, key) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item !== null ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const wrappedSetValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      setStoredValue(valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, wrappedSetValue];
};

export default useStickyState;
