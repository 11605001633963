import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";
import useCanalEpg from "../../hooks/fetch-canal-epg.hook";
import Tabs from "../../components/tabs/tabs.component";
import useCanal from "../../hooks/fetch-canal.hook";
import Slide from "../../components/slide/slide.component";
import Spinner from "../../components/spinner/spinner.component";
import backgroundImage from "../../assets/images/slide-canal.png";
import TabItem from "../../components/tab-item/tab-item.component";
import Button from "../../components/forms/button/button.component";
import GuiaCanal from "../../components/guia-canal/guia-canal.component";
import { MessagesContext } from "../../contexts/messages-context";
import useUser from "../../hooks/user.hook";
import { useHistory } from "react-router-dom";

import { filtrarProgramasPorFecha} from "../../utilities/program-utils";
import { getTodayAndNextDatesMap, getIsoDate } from "../../utilities/utility-functions";

import "./canal.styles.scss";

const CanalPage = ({match}) => {
  const public_id =  match.params.public_id;
  var d = getIsoDate();
  const [fecha] = useState(d);
  const [activeEPGDay, setActiveEPGDay] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const {canal, isLoadingC} = useCanal(public_id);

  const { canalEpg, isLoading } = useCanalEpg(public_id, fecha);
  useEffect(() => {
    setActiveEPGDay(filtrarProgramasPorFecha(fecha, canalEpg));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const tabs = getTodayAndNextDatesMap(6).map((date, i) => ({
    id: i,
    active: i === activeTab,
    title: date.formated,
    handleTabClick: () => {
      setActiveTab(i);
      setActiveEPGDay(filtrarProgramasPorFecha(date.date.toISOString().slice(0,10), canalEpg));
    },
  }));

  const { user, login } = useUser();
  const messages = useContext(MessagesContext);
  const messagesRef = useRef(messages);
  const history = useHistory();

  function handlePlayClick(e)
  {
    if (typeof user === 'undefined') {
      const messages = messagesRef.current;
            messages.add({
              type:'modal',
              text: "Por favor loguearse para ver contenidos",
              onBtnClick: () => login(),
      });
    } else {
      history.push(`/play/${public_id}`);
    }
  };

  return isLoading || isLoadingC? (
    <Spinner />
  ) : (
    <div className="canal">
      <Slide
        backgroundImage={backgroundImage}
        header={
          <Link to="/">
            <div className="return">
              <i className="i i--return"></i>
              <p>Volver</p>
            </div>
          </Link>
        }
        center="center-y"
        size="channel"
      >
        <>
          <div className="channel">
            <div title="Ver Canal" onClick={handlePlayClick} style={{cursor:"pointer"}}>
              <img src={canal.imagen_principal} alt="logo canal" />
            </div>
          </div>
          <h1>{(canal.nombre_fantasia)?canal.nombre_fantasia:canal.nombre}</h1>
          <p>{(canal.descripcion_corta)?canal.descripcion_corta:canal.descripcion}</p>
            <Button icon="play" size="big" onClick={handlePlayClick}>
              Reproducir
            </Button>
          <div className="text" style={{ width: "300px" }}>
            <p className="text-center text-small">
            </p>
          </div>
        </>
      </Slide>
      <section className="main">
        <div className="main__content padding-none">
          <Tabs>
            {tabs.map((header) => {
              const { id, active, handleTabClick, title } = header;
              return (
                <TabItem key={id} active={active} onClick={handleTabClick}>
                  <Link to="#">{title}</Link>
                </TabItem>
              );
            })}
          </Tabs>
        </div>
        <GuiaCanal epg={activeEPGDay}></GuiaCanal>
      </section>
    </div>
  );
};

export default CanalPage;
