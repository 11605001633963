import "./epg.styles.scss";
import { useState, useEffect } from "react";
import React, { useRef } from "react";
import EpgNav from "./epg-nav/epg-nav.component";
import EpgHeader from "./epg-header/epg-header.component";
import EpgCanales from "./epg-canales/epg-canales.component";
import { getNextHours } from "../../utilities/utility-functions";
import EpgProgramas from "./epg-programas/epg-programas.component";

const EPG = ({ canales, horaInicio, reset, onReset }) => {
  const [position, setPos] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  const animate = {
    transform: `translateX(${position}px)`,
    transition: "transform 1s ease",
  };

  const hours = getNextHours(48, horaInicio);

  const hourWidth = 300;

  const divContent = useRef(null);

  const navNext = () => {
    const leftMargin = 200;
    const hiddenWidth =
      divContent.current.scrollWidth -
      divContent.current.offsetWidth -
      leftMargin;
    if (-position <= hiddenWidth) {
      setPrevDisabled(false);
      setPos(position - hourWidth);
    }
    if (-(position - hourWidth) > hiddenWidth) setNextDisabled(true);
  };

  const navPrev = () => {
    if (position !== 0) {
      setNextDisabled(false);
      setPos(position + hourWidth);
    }
    if (position === -hourWidth) setPrevDisabled(true);
  };

  useEffect(() => {
    if (reset) {
      onReset();
      setNextDisabled(false);
      setPrevDisabled(true);
      setPos(0);
    }
  }, [reset, onReset]);

  return (
    <div className="guide">
      <EpgNav
        prevDisabled={prevDisabled}
        nextDisabled={nextDisabled}
        next={navNext}
        prev={navPrev}
      />
      {/* Acá va el ahora */}
      <EpgHeader headers={hours} animate={animate} />
      <div className="guide__content" ref={divContent}>
        <EpgCanales canales={canales} />
        <EpgProgramas
          canales={canales}
          animate={animate}
          hourWidth={hourWidth}
        />
      </div>
    </div>
  );
};

export default EPG;
