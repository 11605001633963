import React from "react";
import { MessagesContext } from "../../contexts/messages-context";
import Modal from "../modal/modal.component";

export const Messages = () => {
  // Muestra el primer mensaje modal,(solo se pueden mostra de a uno)
  const displayModalMessage = ({ pop, messages: { modal = [] } }) => {
    if (modal.length > 0) {
      const firstModal = modal[0];
      const defaultOnClose = () => pop("modal");
      return (
        <Modal
          title={firstModal.title}
          text={firstModal.text}
          onClose={firstModal.onClose || defaultOnClose}
          onBtnClick={firstModal.onBtnClick}
          btnText={firstModal.btnText}
        />
      );
    }
  };

  //Pensado para retornar todo los tipos de mensaje
  const displayMessages = (msgApi) => {
    return <>{displayModalMessage(msgApi)}</>;
  };

  return <MessagesContext.Consumer>{displayMessages}</MessagesContext.Consumer>;
};
