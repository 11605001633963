import React, { useEffect } from "react";
import useUser from "../hooks/user.hook";
import BeClient from "../utilities/cableplay-be-client";
import useStickyState from "../hooks/sticky.hook";
import useErrorHandler from "../hooks/errorhandler.hook";
const AuthContext = React.createContext({});

const TIPO_ANONIMO = "anonimo";
const TIPO_USER = "user";

export const AuthProvider = ({ children }) => {
  const { user, isLoadingUser } = useUser();
  const [auth, setAuth] = useStickyState({}, "auth");
  const handleError = useErrorHandler();

  const addSessionTypeAndUser = (tipo, auth, user = undefined) => {
    return {
      ...auth,
      cds: {
        autorizacion: {
          tipo: tipo,
          token: auth.cds.autorizacion,
          user: user,
        },
      },
    };
  };

  const authenticate = () => {
    if (user) {
      BeClient.autorizar(user)
        .then((authBE) => {
          setAuth(
            addSessionTypeAndUser(TIPO_USER, authBE, user.profile.username)
          );
        })
        .catch((err) => {
          handleError(err);
          BeClient.autorizarAnonimo()
            .then((authBE) => {
              setAuth(addSessionTypeAndUser(TIPO_ANONIMO, authBE));
            })
            .catch((err) => console.log(err));
        });
    } else {
      BeClient.autorizarAnonimo().then((authBE) => {
        setAuth(addSessionTypeAndUser(TIPO_ANONIMO, authBE));
      });
    }
  };

  const removeAuth = () => {
    BeClient.cerrarSesion(auth).catch((error) => console.error(error));
    setAuth({});
  };

  useEffect(() => {
    const handleAuth = async () => {
      if (isLoadingUser) {
        console.log("usuario cargando...");
        return;
      }

      if (auth.cds) {
        if (auth.cds.autorizacion.tipo === TIPO_ANONIMO && user) {
          console.log("cierro sesion porque ahora hay usuario");
          removeAuth();
        } else if (
          auth.cds.autorizacion.tipo === TIPO_USER &&
          (!user || user.profile.username !== auth.cds.autorizacion.user)
        ) {
          console.log(
            "cierro sesion porque el usuario no es el mismo o ya no tengo usuario"
          );
          removeAuth();
        } else {
          console.log("se mantiene la session actual");
          return;
        }
      }

      authenticate();
    };
    handleAuth();
  }, [user, isLoadingUser]);

  const reAuthenticate = () => {
    //controlar que no quede en loop reautenticando siempre
    removeAuth();
    authenticate();
  };

  return (
    <AuthContext.Provider value={{ auth, reAuthenticate }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
