import { errorCodes as beCodes } from "../utilities/cableplay-be-client";
import * as codes from "./codes";
import handleWithMessageFromConfig from "./handlers/handleWithMessageFromConfig";
import handleSesionNoEncontrada from "./handlers/handleSesionNoEncontrada";
import handleSesionUsuarioExpirada from "./handlers/handleSesionUsuarioExpirada";
import handleUsuarioNoHabilitado from "./handlers/handleUsuarioNoHabilitado";
import showModalErrorHandler from "./handlers/showModalErrorHandler";

const messages = new Map([
  [beCodes.cableplay.USUARIO_NO_HABILITADO, "Usuario no habilitado."],
  [beCodes.cds.SESION_NO_ENCONTRADA, "Sesion no encontrada."],
  [beCodes.cds.SESION_IP_INVALIDA, "Ip invalida."],
  [beCodes.cds.SESION_JWT_EXPIRADO, "Error JWT expirado."],
  [beCodes.cds.SESION_JWT_ERROR, "Error JWT."],
  [
    beCodes.cds.SESION_USUARIO_REQUERIDA,
    "Su usuario no le permite ver contenidos.",
  ],
  [beCodes.cds.SETUP_ERROR_CDS_AMP, "Error al reproducir (code:9505)"],
  [
    beCodes.cds.SETUP_NO_UY,
    "Este contenido no se encuentra habilitado para Uruguay.",
  ],
  [
    beCodes.cds.SETUP_SOLO_UY,
    "Este contenido se encuentra únicamente habilitado para Uruguay.",
  ],
  [beCodes.cds.SETUP_AUTHREQ, "9601-AUTHREQ - Contenido con restricciones."],
  [
    beCodes.cds.SETUP_REPRODUCCIONES_SIMULTANEAS,
    "Se ha alcanzado el máximo de reproducciones simultáneas.",
  ],
  [
    beCodes.cds.SETUP_REPRODUCCIONES_SIMULTANEAS_TVOD,
    "Se ha alcanzado el máximo de reproducciones simultáneas de contenidos de alquiler.",
  ],
  [
    beCodes.cds.SETUP_SESIONES_SIMULTANEAS_NO_PERMITIDAS,
    "El contenido no puede ser visualizado, su usuario superó el límite de pantallas simultáneas.",
  ],
  [
    beCodes.cds.SETUP_USUARIO_REQUERIDO_CDS_ALTERNATIVO,
    "Iniciá sesión o creá tu cuenta.",
  ],
  [
    beCodes.cds.SETUP_CDS_DISPOSITIVOS_SIMULTANEOS_NO_PERMITIDOS,
    "Superó el límite de dispositivos por usuario para el servicio.",
  ],
  [
    beCodes.cds.SETUP_CDS_DISPOSITIVO_DESHABILITADO,
    "Dispositivo deshabilitado (cod:9411)",
  ],
  [
    beCodes.cds.SETUP_REPRODUCCION_CONTENIDO_DESHABILITADO,
    "El contenido que intenta visualizar no se encuentra disponible en este momento.",
  ],
  [
    beCodes.cds.SETUP_REPRODUCCION_CONTENIDO_ELIMINADO,
    "El contenido que intenta visualizar ya no está disponible.",
  ],
  [
    beCodes.cds.SETUP_REPRODUCCION_CONTENIDO_NO_EN_PUBLICACION,
    "El contenido no puede ser visualizado en este momento dado que aún no comenzó o ya expiró la fecha de su publicación.",
  ],
  [
    beCodes.cds.SETUP_IP_NO_VALIDA_REPRODUCIR,
    "Error al reproducir (code:9506)",
  ],
  [beCodes.cds.SETUP_CONTENIDO_NO_ENCONTRADO, "No se encontró el contenido."],
  [beCodes.cds.LUA_JWT_EXPIRADO, "Sesion de usuario expirada"],
  [codes.UNKNOWN_ERROR, "Error inesperado."],
]);

const handlers = new Map([
  [beCodes.cds.SESION_USUARIO_REQUERIDA, handleUsuarioNoHabilitado],
  [beCodes.cableplay.USUARIO_NO_HABILITADO, handleUsuarioNoHabilitado],
  [
    beCodes.cds.SETUP_USUARIO_REQUERIDO_CDS_ALTERNATIVO,
    handleUsuarioNoHabilitado,
  ],
  [beCodes.cds.SESION_NO_ENCONTRADA, handleSesionNoEncontrada],
  //[beCodes.cds.SESION_IP_INVALIDA, handleSesionNoEncontrada],
  [beCodes.cds.SESION_JWT_EXPIRADO, handleSesionNoEncontrada],
  //[beCodes.cds.SESION_JWT_ERROR, handleSesionNoEncontrada],
  [beCodes.cds.LUA_JWT_EXPIRADO, handleSesionUsuarioExpirada],
  [
    beCodes.cds.SETUP_AUTHREQ,
    handleWithMessageFromConfig((config) => config.mensajeContenidoRestringido),
  ],
  [codes.UNKNOWN_ERROR, showModalErrorHandler],
]);

const defaultHandler = showModalErrorHandler;
const getHandler = (code) => handlers.get(code) || defaultHandler;
const getMessage = (code) => messages.get(code);

export { getMessage, getHandler };
