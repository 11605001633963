import React from "react";
import SlideHeader from "./slide-header/slide-header.component";
import "./slide.styles.scss";

const SlideItem = ({ header, children, backgroundImage, link="javascript:void(0)"}) => {
  return (
    <div className="slide__item">
      <a href={link}>
        <div className="slide__wrapper">
          <SlideHeader>{header}</SlideHeader>
          <div className="slide__container container">{children}</div>
        </div>
        <div className="slide__background">
          <img src={backgroundImage} alt="" />
        </div>
      </a>
    </div>
  );
};

export default SlideItem;