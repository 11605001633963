import React from "react";
import "./epg-headers.styles.scss";

const EpgHeaders = ({ headers, animate }) => {
  return (

    <div className="epg-header">
        <div className="epg-header__overlay"></div>
        <div className="epg-header__schedule js-guide-scroll" style={animate}>
        {headers.map((header, i) => (
          <div key={i} className="guide__item">
            <p>{header}</p>
          </div>
           ))}
        </div>
    </div>
  );
};

export default EpgHeaders;
