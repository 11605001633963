import React from "react";
import SlideHeader from "./slide-header/slide-header.component";
import "./slide.styles.scss";

const Slide = ({ header, footer, children, backgroundImage, size, center }) => {
  return (
    <section className={`slide ${size ? `slide--${size}` : ""}`}>
      <div className="slide__wrapper">
        <SlideHeader>{header}</SlideHeader>
        <div className={`slide__container container ${center?`flex--${center}`:""}`}>{children}</div>
      </div>
      <div className="slide__background">
        <img src={backgroundImage} alt="" />
      </div>
    </section>
  );
};

export default Slide;
