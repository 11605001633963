import useAuth from "./auth.hook";
import { useEffect, useState } from "react";
import { getCanal } from "../utilities/cds-client";
import useErrorHandler from "./errorhandler.hook";

const useCanal = (public_id) => {
  const [isLoadingC, setIsLoadingC] = useState(true);
  const [canal, setCanal] = useState(null);
  const { auth } = useAuth();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (auth.cds) {
      getCanal(public_id, auth)
        .then(response => {
          const canal = response.data || null;
          setCanal(canal);
          setIsLoadingC(false);
        })
        .catch(err =>{
          handleError(err);
        });
    }
  }, [auth, public_id, handleError]);

  return { canal, isLoadingC };
};

export default useCanal;
