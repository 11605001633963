import React from "react";
import "./header.styles.scss";
import logo from "../../../assets/images/logo-cable-play.png";
import { Link } from "react-router-dom";

import User from "../../user/user.component";
import useAuth from "../../../hooks/auth.hook";

const Header = () => {
  const {auth} = useAuth();
  return (
    <header className="header">
      <div className="header__container container">
        <div className="header__item">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Cable Play" />
            </Link>
          </div>
        </div>
        <div className="header__item header__item--center">
          <nav className="nav">
            <ul className="nav__container">
              <li className="nav__item">
                <Link to="/guia" title="En vivo">
                  Guía de Canales
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header__item">
          <div className="flex flex--center-y">
            {auth && auth.operador ? <div className="operador"><img alt={auth.operador.organizacion} src={auth.operador.imagenes.logo}></img></div>  : ""}
            <User />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
